import React from "react";
import Conclusion from "../../components/project/Conclusion";
import CommonProjectComponent from "../../components/project/CommonProjectComponent";
import ProblemSolution from "../../components/project/ProblemSolution";
import ProjectDetailBanner from "../../components/project/ProjectDetailBanner";
import PreviousNext from "../../components/PreviousNext";
import { Seo } from "../../components/seo";
import Layout from "../../components/layout";

const AutoRecruits = () => {
  const headingbanner = {
    title: `Auto Recruits`,
    content: `A Job-Portal Platform`,
  };

  const data = {
    images: ["auto-recruits-web.webp", "auto-recruits-2.webp"],
    firstAlt: "UI UX Dashboard to overview applicants status",
    firstTitle: "Job Applicants Overview",
    secondAlt: "Applicants list for a specific job position",
    secondTitle: "Applicants list",
  };

  const problemSolutionContent = {
    sidebar: [
      {
        title: "Industry",
        text: ["SaaS"],
      },
      {
        title: "What we did",
        text: ["Product Development", "UI UX Designing", "UI Development"],
      },
      {
        title: "Platform",
        text: ["Web Application"],
      },
    ],
    mainContent: [
      {
        title: "Problem",
        content: [
          `Searching for a job and finding the right talent for the job was never an easy task. Especially, when you are looking and offering an industry-specific job. `,
          `Being an industry-specific job portal platform, they also had certain challenges too. The primary challenge was to improve the job discovery and the application process. `,
          `They wanted to improve the user engagement of the platform and offer enhanced features for increased platform usage.`,
          `We were tasked to revamp their portal and website design while also optimising the current information architecture of the platform. `
        ],
      },
      {
        title: "Solution",
        content: [
          `We first researched and understood their industry and business offerings in order to create an exceptional user interface. `,
          `Our aim was to offer a unique user interface that can suit their niche. We did it by introducing a communication channel for effective communication and revitalising their user onboarding process.`,
          `We created an intuitive job application management pipeline to maintain a consistent user experience and reduce dropout rates.`
        ],
      },
    ],
    image: ["auto-recruits-problem.webp"],
  };

  const projectData = {
    title: `Landing Page`,
    para: [
      `We created a landing page and categorised the jobs based on various positions for a better user experience. This page offers an overview of the platform for improved engagement. `,
    ],
    content: {
      image: ["auto-recruits-projectone.webp"],
    },
  };

  const projectData2 = {
    title: `Mobile Version`,
    para: [
      `We also created a mobile version of the platform to improve accessibility and provided options to track the applications for a better user experience. `,
    ],
    content: {
      image: ["auto-recruits-projecttwo.webp"],
    },
  };

  const projectData3 = {
    title: `Tasks`,
    para: [
      `Through this interface, we wanted to simplify the hiring process by creating a task list and upcoming interviews. We also provided a chat option for better communication. `,
    ],
    content: {
      image: [
        "auto-recruits-projectthree.webp",
        "auto-recruits-projectthree-2.webp",
        "auto-recruits-projectthree-3.webp",
        "auto-recruits-projectthree-4.webp",
        "auto-recruits-projectthree-5.webp",
        "auto-recruits-projectthree-fial.webp",
        "auto-recruits-projectthree-7.webp",
        "auto-recruits-projectthree-8.webp",

      ],
    },
  };

  const conclusionData = [
    {
      para: [
        `AutoRecruits is an industry-specific job portal, which bridges the gap between dealerships/ workshops and deserving candidates in the industry by providing a technology-driven platform for making the hiring process easier.`,
        `However, they wanted to improve their platform to increase user engagement and improve the user experience. `,
        `We researched their industry and understood their requirements to offer an exceptional user experience while also ensuring that they achieve their business objective. `
      ],
    },
  ];

  return (
    <Layout>
      <div className="service-page">
        <ProjectDetailBanner data={data} headingbanner={headingbanner} />
        <ProblemSolution data={problemSolutionContent} />
        <CommonProjectComponent data={projectData} mode="cream" />
        <CommonProjectComponent data={projectData2} />
        <CommonProjectComponent data={projectData3} mode="dark" />
        <Conclusion conclusionData={conclusionData} />
        <PreviousNext currentProjectId={27} />
      </div>
    </Layout>
  );
};

export default AutoRecruits;

export const Head = () => (
  <Seo title="Auto Recruits - Octet Design Studio" description="We did ideation, UI UX designing and frontend development of Auto Recruits, an automobile personnel recruitment platform." />
)